<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="estateCopy"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:top>
              <br>
              <v-toolbar
                flat
              >
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="resetValues"
                      :disabled="FIELDS_DISABLED"
                    >
                      Add New
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-combobox
                              dense
                              v-model="editedItem.ownership"
                              :items="ownershipOptions"
                              label="Ownership"
                              :disabled="FIELDS_DISABLED"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-autocomplete
                              dense
                              no-filter
                              autocomplete="nofill"
                              v-model="estateAssetAddress"
                              :items="addressOptions"
                              :search-input.sync="editedItem.address"
                              item-text="address"
                              label="Address"
                              hide-no-data
                              :disabled="FIELDS_DISABLED"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-select
                              dense
                              v-model="editedItem.purpose"
                              :items="estateAssetsSectionData.purposeOptions"
                              label="Primary Purpose"
                              :disabled="FIELDS_DISABLED"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              dense
                              type="text"
                              v-model="estateAssetValue"
                              @focus="valueNumberType"
                              @blur="valueTextType"
                              placeholder="Value NZD, $"
                              label="Value NZD, $"
                              :disabled="FIELDS_DISABLED"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-select
                              dense
                              v-model="editedItem.value_basis"
                              :items="estateAssetsSectionData.valueBasisOptions"
                              label="Value Basis"
                              :disabled="FIELDS_DISABLED"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-select
                              dense
                              v-model="editedItem.security"
                              :items="estateAssetsSectionData.securityOptions"
                              label="Security to bank?"
                              :disabled="FIELDS_DISABLED"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="saveData"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">
                      Are you sure you want to delete this item?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <br>
        </v-col>
      </v-row>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';
import { estateAssetsSectionData } from './dataOptions';
import { getAddressList } from '@/services/addresses';

import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';
import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'RealEstateAssetsSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin, FeedOptionsMixin],

  props: {
    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      estateAssetsSectionData,

      isSectionVisible: false,

      initialEstateAssets: [],
      estateAssets: [],
      estateCopy: [],

      estateAssetValue: '',

      addressOptions: [],

      estateAssetAddress: null,

      feedMainOptionsKey: {
        ownership: 'estateAssetsSectionData.ownershipOptions',
      },

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Ownership', value: 'ownership', sortable: false },
        { text: 'Address', value: 'address', sortable: false },
        { text: 'Primary Purpose', value: 'purpose', sortable: false },
        { text: 'Value NZD, $', value: 'value', sortable: false },
        { text: 'Value Basis', value: 'value_basis', sortable: false },
        { text: 'Security to bank?', value: 'security', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        ownership: [],
        address: '',
        purpose: '',
        value: '',
        value_basis: '',
        security: '',
      },
      defaultItem: {
        ownership: [],
        address: '',
        purpose: '',
        value: '',
        value_basis: '',
        security: '',
      },
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialEstateAssets = clone(this.existedData);
    this.estateAssets = clone(this.existedData);
    this.estateCopy = clone(this.estateAssets);

    this.numberFieldsFormat();

    this.$emit('componentReady');
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  methods: {
    resetValues() {
      this.estateAssetValue = '';
    },

    textFieldsFormat() {
      this.estateCopy.forEach((estate, index) => {
        this.estateAssets[index].value = this.$options.filters.textFormatter(estate.value);
      });
    },

    numberFieldsFormat() {
      this.estateAssets.forEach((estate, index) => {
        this.estateCopy[index].value = this.$options.filters.integerFormatter(estate.value);
      });
    },

    editItem(item) {
      this.editedIndex = this.estateCopy.indexOf(item);
      this.editedItem = { ...item };
      this.estateAssetAddress = this.editedItem.address;
      this.estateAssetValue = this.$options.filters.integerFormatter(this.editedItem.value);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.estateCopy.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.estateCopy.splice(this.editedIndex, 1);
      this.estateAssets.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    saveData() {
      if (this.editedIndex > -1) {
        Object.assign(this.estateCopy[this.editedIndex], this.editedItem);
      } else {
        this.estateCopy.push(this.editedItem);
      }

      this.estateAssets = clone(this.estateCopy);
      this.textFieldsFormat();
      this.numberFieldsFormat();

      this.close();
    },

    valueTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.estateAssetValue = this.$options.filters.integerFormatter(this.estateAssetValue);
      this.editedItem.value = this.$options.filters.textFormatter(this.estateAssetValue);
    },

    valueNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.estateAssetValue = this.$options.filters.textFormatter(this.estateAssetValue);
    },
  },

  watch: {
    'editedItem.address': {
      async handler(searchString) {
        this.addressOptions = await getAddressList(searchString);
      },
    },
  },
};
</script>
