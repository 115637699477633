var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', [_c('v-data-table', {
          staticClass: "elevation-1",
          attrs: {
            "headers": _vm.headers,
            "items": _vm.estateCopy,
            "hide-default-footer": ""
          },
          scopedSlots: _vm._u([{
            key: "top",
            fn: function fn() {
              return [_c('br'), _c('v-toolbar', {
                attrs: {
                  "flat": ""
                }
              }, [_c('v-dialog', {
                attrs: {
                  "max-width": "500px"
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on,
                        attrs = _ref.attrs;
                    return [_c('v-btn', _vm._g(_vm._b({
                      staticClass: "mb-2",
                      attrs: {
                        "color": "primary",
                        "disabled": _vm.FIELDS_DISABLED
                      },
                      on: {
                        "click": _vm.resetValues
                      }
                    }, 'v-btn', attrs, false), on), [_vm._v(" Add New ")])];
                  }
                }]),
                model: {
                  value: _vm.dialog,
                  callback: function callback($$v) {
                    _vm.dialog = $$v;
                  },
                  expression: "dialog"
                }
              }, [_c('v-card', [_c('v-card-title', [_c('span', {
                staticClass: "text-h5"
              }, [_vm._v(_vm._s(_vm.formTitle))])]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
                attrs: {
                  "cols": "12",
                  "sm": "6"
                }
              }, [_c('v-combobox', {
                attrs: {
                  "dense": "",
                  "items": _vm.ownershipOptions,
                  "label": "Ownership",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: _vm.editedItem.ownership,
                  callback: function callback($$v) {
                    _vm.$set(_vm.editedItem, "ownership", $$v);
                  },
                  expression: "editedItem.ownership"
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "12",
                  "sm": "6"
                }
              }, [_c('v-autocomplete', {
                attrs: {
                  "dense": "",
                  "no-filter": "",
                  "autocomplete": "nofill",
                  "items": _vm.addressOptions,
                  "search-input": _vm.editedItem.address,
                  "item-text": "address",
                  "label": "Address",
                  "hide-no-data": "",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "update:searchInput": function updateSearchInput($event) {
                    return _vm.$set(_vm.editedItem, "address", $event);
                  },
                  "update:search-input": function updateSearchInput($event) {
                    return _vm.$set(_vm.editedItem, "address", $event);
                  }
                },
                model: {
                  value: _vm.estateAssetAddress,
                  callback: function callback($$v) {
                    _vm.estateAssetAddress = $$v;
                  },
                  expression: "estateAssetAddress"
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "12",
                  "sm": "6"
                }
              }, [_c('v-select', {
                attrs: {
                  "dense": "",
                  "items": _vm.estateAssetsSectionData.purposeOptions,
                  "label": "Primary Purpose",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: _vm.editedItem.purpose,
                  callback: function callback($$v) {
                    _vm.$set(_vm.editedItem, "purpose", $$v);
                  },
                  expression: "editedItem.purpose"
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "12",
                  "sm": "6"
                }
              }, [_c('v-text-field', {
                attrs: {
                  "dense": "",
                  "type": "text",
                  "placeholder": "Value NZD, $",
                  "label": "Value NZD, $",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "focus": _vm.valueNumberType,
                  "blur": _vm.valueTextType
                },
                model: {
                  value: _vm.estateAssetValue,
                  callback: function callback($$v) {
                    _vm.estateAssetValue = $$v;
                  },
                  expression: "estateAssetValue"
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "12",
                  "sm": "6"
                }
              }, [_c('v-select', {
                attrs: {
                  "dense": "",
                  "items": _vm.estateAssetsSectionData.valueBasisOptions,
                  "label": "Value Basis",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: _vm.editedItem.value_basis,
                  callback: function callback($$v) {
                    _vm.$set(_vm.editedItem, "value_basis", $$v);
                  },
                  expression: "editedItem.value_basis"
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "12",
                  "sm": "6"
                }
              }, [_c('v-select', {
                attrs: {
                  "dense": "",
                  "items": _vm.estateAssetsSectionData.securityOptions,
                  "label": "Security to bank?",
                  "disabled": _vm.FIELDS_DISABLED
                },
                model: {
                  value: _vm.editedItem.security,
                  callback: function callback($$v) {
                    _vm.$set(_vm.editedItem, "security", $$v);
                  },
                  expression: "editedItem.security"
                }
              })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
                attrs: {
                  "color": "blue darken-1",
                  "text": ""
                },
                on: {
                  "click": _vm.close
                }
              }, [_vm._v(" Cancel ")]), _c('v-btn', {
                attrs: {
                  "color": "blue darken-1",
                  "text": ""
                },
                on: {
                  "click": _vm.saveData
                }
              }, [_vm._v(" Save ")])], 1)], 1)], 1), _c('v-dialog', {
                attrs: {
                  "max-width": "500px"
                },
                model: {
                  value: _vm.dialogDelete,
                  callback: function callback($$v) {
                    _vm.dialogDelete = $$v;
                  },
                  expression: "dialogDelete"
                }
              }, [_c('v-card', [_c('v-card-title', {
                staticClass: "text-h5"
              }, [_vm._v(" Are you sure you want to delete this item? ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
                attrs: {
                  "color": "blue darken-1",
                  "text": ""
                },
                on: {
                  "click": _vm.closeDelete
                }
              }, [_vm._v("Cancel")]), _c('v-btn', {
                attrs: {
                  "color": "blue darken-1",
                  "text": ""
                },
                on: {
                  "click": _vm.deleteItemConfirm
                }
              }, [_vm._v("OK")]), _c('v-spacer')], 1)], 1)], 1)], 1)];
            },
            proxy: true
          }, {
            key: "item.actions",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.editItem(item);
                  }
                }
              }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.deleteItem(item);
                  }
                }
              }, [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }), _c('br')], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }